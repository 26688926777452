exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-employee-js": () => import("./../../../src/pages/employee.js" /* webpackChunkName: "component---src-pages-employee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-news-category-wp-news-acf-news-category-js": () => import("./../../../src/pages/News/Category/{WpNews.acf_news__category}.js" /* webpackChunkName: "component---src-pages-news-category-wp-news-acf-news-category-js" */),
  "component---src-pages-news-wp-news-slug-js": () => import("./../../../src/pages/News/{WpNews.slug}.js" /* webpackChunkName: "component---src-pages-news-wp-news-slug-js" */),
  "component---src-pages-projects-category-wp-project-acf-project-category-js": () => import("./../../../src/pages/Projects/Category/{WpProject.acf_project__category}.js" /* webpackChunkName: "component---src-pages-projects-category-wp-project-acf-project-category-js" */),
  "component---src-pages-projects-wp-project-slug-js": () => import("./../../../src/pages/Projects/{WpProject.slug}.js" /* webpackChunkName: "component---src-pages-projects-wp-project-slug-js" */),
  "component---src-pages-providers-wp-provider-slug-js": () => import("./../../../src/pages/Providers/{WpProvider.slug}.js" /* webpackChunkName: "component---src-pages-providers-wp-provider-slug-js" */),
  "component---src-pages-recyclopedia-search-js": () => import("./../../../src/pages/RecyclopediaSearch.js" /* webpackChunkName: "component---src-pages-recyclopedia-search-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/Search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-category-wp-service-acf-services-category-js": () => import("./../../../src/pages/Services/Category/{WpService.acf_services__category}.js" /* webpackChunkName: "component---src-pages-services-category-wp-service-acf-services-category-js" */),
  "component---src-pages-services-wp-service-slug-js": () => import("./../../../src/pages/Services/{WpService.slug}.js" /* webpackChunkName: "component---src-pages-services-wp-service-slug-js" */),
  "component---src-pages-wp-page-slug-js": () => import("./../../../src/pages/{WpPage.slug}.js" /* webpackChunkName: "component---src-pages-wp-page-slug-js" */)
}

