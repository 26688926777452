import React, { useState } from "react"

const defaultState = {
  searchTerm: "",
}
export const searchContext = React.createContext(defaultState)

//this is a wrapper class that will be placed around all gatsby pages, allowing us to pass states
const ContextProvider = props => {
  const [searchTerm, setSearchTerm] = useState("")
  const [filters, setFilters] = useState([])
  const [resultCount, setResultCount] = useState(0)
  const [results, setResults] = useState([])

  return (
    <searchContext.Provider
      value={[
        searchTerm,
        setSearchTerm,
        filters,
        setFilters,
        resultCount,
        setResultCount,
        results,
        setResults,
      ]}
    >
      {props.children}
    </searchContext.Provider>
  )
}

const returner = ({ element }) => <ContextProvider>{element}</ContextProvider>

export default returner
