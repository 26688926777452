import ContextProvider from "./src/components/ContextProvider/ContextProvider"
import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Script type="text/javascript">{`function OptanonWrapper() {  } `}</Script>
      {element}
      <Script src="https://cdn.cookielaw.org/consent/fd31c61a-15d6-4769-9802-5eafcc1098ba.js"></Script>
      <Script
        id="first-unique-id"
        dangerouslySetInnerHTML={{
          __html: `document.querySelectorAll(".entry-title-header").forEach(function (el) {
  el.addEventListener("click", function () {
    document.querySelectorAll(".teamsInfoShow").forEach(function (pl) {
      removeClass(pl, "teamsInfoShow")
      addClass(pl, "teamsInfoHide")
    })

    var info = document.getElementById(this.id + "Show")

    removeClass(info, "teamsInfoHide")
    addClass(info, "teamsInfoShow")
  })
})

function addClass(elements, myClass) {
  if (!elements) {
    return
  }

  if (typeof elements === "string") {
    elements = document.querySelectorAll(elements)
  } else if (elements.tagName) {
    elements = [elements]
  }

  for (var i = 0; i < elements.length; i++) {
    if ((" " + elements[i].className + " ").indexOf(" " + myClass + " ") < 0) {
      elements[i].className += " " + myClass
    }
  }
}

function removeClass(elements, myClass) {
  if (!elements) {
    return
  }

  if (typeof elements === "string") {
    elements = document.querySelectorAll(elements)
  } else if (elements.tagName) {
    elements = [elements]
  }

  var reg = new RegExp("(^| )" + myClass + "($| )", "g")

  for (var i = 0; i < elements.length; i++) {
    elements[i].className = elements[i].className.replace(reg, " ")
  }
}

document.addEventListener("click", function (e) {
  if (e.target.tagName !== "A") return

  if (e.target.href && e.target.href.indexOf("#") !== -1) {
    scrollAnchors(e, e.target)
  }
})

function scrollAnchors(e, respond = null) {
  function distanceToTop(el) {
    return Math.floor(el.getBoundingClientRect().top)
  }

  e.preventDefault()
  var targetID = respond
    ? respond.getAttribute("href")
    : this.getAttribute("href")
  var targetAnchor = document.querySelector(targetID)
  if (!targetAnchor) return
  var originalTop = distanceToTop(targetAnchor)
  window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" })
  var checkIfDone = setInterval(function () {
    var atBottom =
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2
    if (distanceToTop(targetAnchor) === 100 || atBottom) {
      targetAnchor.tabIndex = "-1"
      targetAnchor.focus()

      // Let's make sure the History API even exists first..
      if ("history" in window) {
        window.history.pushState("", "", targetID)
      } else {
        // Do it the old-fashioned way!
        window.location = targetID
      }

      clearInterval(checkIfDone)
    }
  }, 100)
}
`,
        }}
      ></Script>
    </>
  )
}

export const wrapRootElement = ContextProvider
